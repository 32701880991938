@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  transition: $property;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transform-origin($property) {
  -webkit-transform-origin: $property;
  -moz-transform-origin: $property;
  -ms-transform-origin: $property;
  transform-origin: $property;
}

@mixin user-select($property) {
  -webkit-user-select: $property;
  -moz-user-select: $property;
  -ms-user-select: $property;
  user-select: $property;
}

@mixin breakpoint($class) {

  @if $class==xxs {
    @media (max-width: 479px) {
      @content;
    }
  }

  @if $class==xs {
    @media (max-width: 576px) {
      @content;
    }
  }

  @if $class==h-xs {
    @media (max-height: 576px) {
      @content;
    }
  }

  @if $class==xss {
    @media (max-width: 639px) {
      @content;
    }
  }

  @if $class==sm {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $class==sm2 {
    @media (max-width: 800px) {
      @content;
    }
  }

  @if $class==xsm {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @if $class==xsm2 {
    @media (max-width: 1023px) {
      @content;
    }
  }

  @else if $class==lg {
    @media (max-width: 1280px) {
      @content;
    }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}