//SIDEBAR
.sidebar__open-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.3rem;
  margin-left: 0.5rem;
  cursor: pointer;
  z-index: 1040;
  span {
    font-size: 1.5rem;
    color: var(--light);
  }
}

.sidebar {
  overflow: hidden;
  position: fixed;
  min-width: var(--sidebarClose);
  max-width: var(--sidebarClose);
  height: 100%;
  background-color: #f3f2f1;
  z-index: 1040;
  transition: all 0.5s;
  font-size: 0.875rem;

  //* LOGO
  .sidebar__logo {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    img {
      height: 30px;
      position: absolute;
      top: 50%;
      @include transform(translate(0, -50%));
    }
    .sidebar__logo--icon {
      left: 11px;
    }
    .sidebar__logo--text {
      left: 45px;
    }
    .sidebar__close {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      right: 0;
      cursor: pointer;
      @include transition(opacity 0.3s);
      span {
        position: relative;
        i {
          margin: 0.2rem 0.5rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  //* WRAPPER
  .sidebar__wrapper {
    font-weight: 500;
    position: relative;
  }

  //* MENU ITEM
  li.sidebar__item {
    width: 100%;
    @include transition(all 0.2s);
    a.nav-link {
      color: var(--dark-1);
      position: relative;
      i {
        position: absolute;
        font-size: 18px;
        left: 8px;
        opacity: 0.8;
        pointer-events: none;
      }
      p {
        margin-bottom: 0;
        padding-left: 30px;
        font-weight: 600;
        transition: all 0.5s;
        pointer-events: none;
      }
      &.dropdown-toggle {
        &::after {
          position: absolute;
          top: 15px;
          right: 10px;

          font-family: "BestDocIcons" !important;
          content: "\f0d7";
          font-size: 6px;
          color: var(--dark-1);

          opacity: 0;
          @include transition(all 0.5s);
        }
        &[aria-expanded="true"] {
          &::after {
            content: "\f0d8";
          }
        }
      }
      &.nav-link-first {
        white-space: nowrap;
        border-left: 3px solid transparent;
        &[aria-expanded="true"] {
          border-color: var(--dark-3);
          border-bottom: 1px solid var(--dark-3);
          //background-color: rgba(#999, 0.1);
        }
        &:hover {
          background-color: rgba(#999, 0.1);
          &[aria-expanded="true"] {
            background-color: transparent;
          }
        }
      }
      &.nav-link-child {
        cursor: pointer;
        .menu__sigla {
          position: absolute;
          font-style: normal;
          font-size: 12px;
          font-weight: 400;
        }
        i {
          left: 13px;
        }
        p {
          font-weight: 400;
          white-space: nowrap;
        }
        &:hover {
          i{
            font-weight: 900;
          }
          p {
            font-weight: 600;
          }
        }
        &.dropdown-toggle {
          &[aria-expanded="true"] {
            //background-color: rgba(#999, 0.1);
            border-bottom: 1px solid var(--dark-3);
            i{
              font-weight: 900;
            }
            p{
              font-weight: 600;
            }
          }
        }
      }
    }
    //* INTERACTIONS
    &:hover {
      //background-color: rgba(#999, 0.1);
      a.nav-link.nav-link-first {
        border-color: var(--dark-3);
      }
    }
  }

  //* INTERACTIONS
  &:hover {
    min-width: var(--sidebarOpen);
    max-width: var(--sidebarOpen);
    overflow-y: auto;
    li.sidebar__item {
      a.nav-link {
        &.dropdown-toggle {
          &::before {
            opacity: 1;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
    @include breakpoint(sm2) {
      min-width: 0;
      max-width: 0;
    }
  }

  &.sidebar__openShorCut {
    min-width: var(--sidebarOpen);
    max-width: var(--sidebarOpen);
    overflow-y: auto;
    li.sidebar__item {
      a.nav-link {
        &.dropdown-toggle {
          &::before {
            opacity: 1;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
    @include breakpoint(sm2) {
      min-width: 0;
      max-width: 0;
    }
  }

  &.sidebar__open {
    min-width: 100%;
    max-width: 100%;
    overflow-y: auto;
    .sidebar__close {
      visibility: visible;
      opacity: 1;
    }
    li.sidebar__item {
      a.nav-link {
        &.dropdown-toggle {
          &::before {
            opacity: 1;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  @include breakpoint(sm2) {
    min-width: 0;
    max-width: 0;
  }
}

// MENU 2
.list-child {
  background-color: rgba(#999, 0.1);
  .nav-links-last {
    app-menu-item2 {
      .nav-link {
        i {
          display: none;
        }
        p {
          position: relative;
          font-size: 12px;
          &::before {
            content: " ";
            position: absolute;
            left: 0;
            color: var(--dark-1);
            width: 6px;
            height: 6px;
            background: var(--dark-1);
            border-radius: 20px;
            top: 6px;
          }
        }
      }
    }
  }
}

// INPOS

.menu__inpos{
  i{
    font-weight: 900 !important;
    color: #0078D4 !important;
  }
  p{
    font-weight: 600 !important;
    &:before{
      background: #0078D4 !important;
    }
  }
}

.menu__inpos-parent{
  border-color: #0078D4 !important;
}
