.gform__tree{
  position: fixed;
  top: 90px;
  min-height: 180px;
  max-height: 80vh;
  display: inline-block;
  width: 0%;
  background-color: var(--light);
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 6px 0 0 6px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  z-index: 1070;
  @include transition(all 0.5s);
}