$primary: #0078D4;
$secundary: #004F8D;

$primary-5: scale-color($primary, $lightness: 5%);
$primary-10: scale-color($primary, $lightness: 10%);
$primary-20: scale-color($primary, $lightness: 20%);
$primary-30: scale-color($primary, $lightness: 30%);
$primary-40: scale-color($primary, $lightness: 40%);
$primary-50: scale-color($primary, $lightness: 50%);
$primary-60: scale-color($primary, $lightness: 60%);
$primary-70: scale-color($primary, $lightness: 70%);
$primary-80: scale-color($primary, $lightness: 80%);
$primary-90: scale-color($primary, $lightness: 90%);
$primary-95: scale-color($primary, $lightness: 95%);

$shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

$light: #fff;
$dark-1: #333;
$dark-2: #666;
$dark-3: #999;

$red: rgb(255, 35, 35);
$red-dark: darken($red, 10%);
$red-light: lighten($red, 10%);

$orange: #F37807;
$orange-dark: darken($orange, 10%);
$orange-light: lighten($orange, 10%);

$green: #66C372;
$green-dark: darken($green, 10%);
$green-light: lighten($green, 10%);

$input-h: 38px;