.mat-expansion-panel{
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;
    border-radius: 0 !important;
    margin: 1rem !important;
    overflow: inherit !important;
    @include transition(all .5s);
    mat-panel-title{
        i{
            font-size: 1rem;
            @include transition(all .5s);
            &:hover{
                color: #0078D4;
            }
        }
    }
    &:first-of-type{
        margin-top: 2rem !important;
    }
    &:hover{
        background-color: #eaeaea;
    }
}

mat-list-item{
    cursor: grab;
    background-color: var(--light);
    padding: 0.6rem;
    margin: 1rem 0.3rem;
    @include transition(all .5s);
    &:hover{
        box-shadow: var(--shadow);
    }
}