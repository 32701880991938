/*
  ! FONTS
    * Title: Open Sans
      - Regular       (400) 
      - Semi Bold     (600)
      - Bold          (700)
      - Extra Bold    (800)
    * Content: Raleway
      - Light         (300)
      - Light Italic  (300)
      - Regular       (400)
      - Italic        (400)
      - Semi Bold     (600)
*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&family=Raleway:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap");

/*
  ! SASS PARTIALS
    * SASS Variables
    * SASS Mixins
    * Icons
    * DataTable
*/
@import "./assets/styles/partials/variables";
@import "./assets/styles/partials/mixins";
@import "./assets/styles/partials/fabric";
@import "./assets/styles/partials/material";
@import "./assets/styles/partials/sidebar";
@import "./assets/styles/partials/datatable";
@import "./assets/styles/partials/jstree";
@import "./assets/styles/partials/icons";
@import "./assets/styles/partials/tooltip";
@import "./assets/styles/partials/buttons";
/* 
@import "./assets/styles/partials/icons";
*/

/*
  ! EXTERNAL STYLES
    * NG-Select
*/
@import "~@ng-select/ng-select/themes/default.theme.css";



.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.85 !important;
  }
}

/*
  ! Z_INDEX
  * .ms-Dialog (modales), .ms-ContextualHost (menu contextuales): 1080
  * modal-page: 1070
  * ms-PanelHost, ms-Panel (paneles laterales): 1070
  * .ms-Overlay: 1060
  * sideBar: 1040
  * user-nav: 1030
  * command-bar: 1030
  * tooltip: 1020
  * checkBox -> before: 1010
  * checkBox -> after: 1000
*/

:root {
  // FONTS
  --title: "Open Sans", sans-serif;
  --content: "Raleway", sans-serif;

  // SIDEBAR WIDTH
  --sidebarClose: 40px; //65px;
  --sidebarOpen: 306px;

  //* COLOR PRINCIPAL
  --primary: #{$primary};

  //* COLOR SECUNDARIO
  --secundary: #{$secundary};

  //* VARIACIONES COLOR PRINCIPAL
  --primary-5: #{$primary-5};
  --primary-10: #{$primary-10};
  --primary-20: #{$primary-20};
  --primary-30: #{$primary-30};
  --primary-40: #{$primary-40};
  --primary-50: #{$primary-50};
  --primary-60: #{$primary-60};
  --primary-70: #{$primary-70};
  --primary-80: #{$primary-80};
  --primary-90: #{$primary-90};
  --primary-95: #{$primary-95};

  //* LUCES
  --light: #{$light};
  --dark-1: #{$dark-1};
  --dark-2: #{$dark-2};
  --dark-3: #{$dark-3};

  //* COLORES COMPLEMENTARIOS
  --red: #{$red};
  --red-dark: #{$red-dark};
  --red-light: #{$red-light};
  --green: #{$green};
  --green-dark: #{$green-dark};
  --green-light: #{$green-light};
  --orange: #{$orange};
  --orange-dark: #{$orange-dark};
  --orange-light: #{$orange-light};

  //* VARIABLES GENERALES
  // SHADOW
  --shadow: #{$shadow};
  // MARGIN LEFT
  --margin-l: calc(var(--sidebarClose) + 20px);

  //* VARIABLES FORMULARIO
  --input-h: #{$input-h};
  --border: var(--secundary);
}

body {
  font-family: var(--content);
  margin: 0;
  background-color: var(--light);
  overflow-x: hidden;
  color: var(--dark-2);
}

:focus {
  outline: none !important;
  box-shadow: none;
}

.ng-select {
  display: block !important;
}

.ng-select-focused>.ng-select-container {
  box-shadow: none !important;
}

.ng-select .ng-select-container:hover {
  box-shadow: none !important;
  border-color: #767676;
}

input:checked~.ms-CheckBox-field {
  &:before {
    border: 10px solid #0078d7;
    background-color: #0078d7;
  }

  &:after {
    content: "\E73E";
    font-family: FabricMDL2Icons;
    display: block;
    position: absolute;
    font-weight: 900;
    background-color: transparent;
    font-size: 13px;
    top: 0;
    color: #fff;
    line-height: 20px;
    width: 20px;
    text-align: center;
  }
}


//* GENERAL STYLES (necesario para algunos comportamientos especificos)
// SIDEBAR
// .sidebar {
//   &:hover {
//     li.sidebar__item {
//       a.nav-link{
//         &.dropdown-toggle{
//           &::after{
//             opacity: 1;
//           }
//           &::before{
//             opacity: 1;
//           }
//         }
//       }
//     }
//   }
// }

.sticker {
  text-align: center; /* Alinea el texto al centro horizontalmente */
  vertical-align: middle; /* Alinea el texto al centro verticalmente (opcional) */
}

// UL
.ul__style {
  list-style: none;
  /* Remove default bullets */
}

.ul__style li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--primary);
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
}

// SPLASH SCREEN
.splashScreenClass {
  .splash__container {
    position: absolute;
    top: 50%;
    left: 50%;

    box-shadow: var(--shadow);
    @include transform(translate(-50%, -50%));

    .splash__content {
      position: relative;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      padding: 1rem;

      .splash__bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: var(--primary);
        -webkit-animation: SPLASH-BAR 2s infinite;
        animation: SPLASH-BAR 2s infinite;
      }

      .splash__text {
        position: relative;
        width: 350px;

        h4 {
          color: var(--primary);
          font-size: 1.2rem;
          margin-bottom: 2rem;

          .splash__point1 {
            opacity: 0;
            -webkit-animation: SPLASH-POINT 2s infinite;
            animation: SPLASH-POINT 2s infinite;
          }

          .splash__point2 {
            opacity: 0;
            -webkit-animation: SPLASH-POINT2 2s infinite;
            animation: SPLASH-POINT2 2s infinite;
          }

          .splash__point3 {
            opacity: 0;
            -webkit-animation: SPLASH-POINT3 2s infinite;
            animation: SPLASH-POINT3 2s infinite;
          }
        }

        p {
          width: 300px;
          color: var(--dark-3);
          font-size: 0.875rem; // 14px

          @include breakpoint(xs) {
            width: 100%;
          }

          @include breakpoint(h-xs) {
            display: none;
          }
        }

        .splash__cr {
          position: absolute;
          bottom: 0;
          color: var(--dark-3);
          font-size: 0.75rem; // 12px

          @include breakpoint(xs) {
            bottom: -10px;
          }
        }

        @include breakpoint(xs) {
          width: 100%;
        }
      }

      .splash__img {
        padding: 2rem 1rem 2rem 2rem;

        img {
          width: 360px;
        }

        @include breakpoint(xxs) {
          img {
            width: 260px;
          }
        }

        @include breakpoint(h-xs) {
          padding-left: 5rem;
          img {
            height: 200px;
            width: auto;
          }
        }
      }

      @include breakpoint(sm2) {
        flex-flow: row wrap;
      }
    }
  }
}

@-webkit-keyframes SPLASH-BAR {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-moz-keyframes SPLASH-BAR {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-o-keyframes SPLASH-BAR {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes SPLASH-BAR {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes SPLASH-POINT {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes SPLASH-POINT {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes SPLASH-POINT {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes SPLASH-POINT {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes SPLASH-POINT2 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes SPLASH-POINT2 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes SPLASH-POINT2 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes SPLASH-POINT2 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes SPLASH-POINT3 {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes SPLASH-POINT3 {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes SPLASH-POINT3 {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes SPLASH-POINT3 {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// WRAPPER
.wrapper {
  position: absolute;
  top: 0;
  width: 100%;
}

// VALIDATION
.is-valid:not(.ms-Dropdown-select) {
  border: 1px solid var(--green);
}

.is-invalid:not(.ms-Dropdown-select) {
  border: 1px solid var(--red);
}

.is-valid.ms-Dropdown-select {
  .ng-select-container {
    border: 1px solid var(--green);
  }
}

.is-invalid.ms-Dropdown-select {
  .ng-select-container {
    border: 1px solid var(--red);
  }
}

// FABRIC COMPONENT STYLES


.dropdown-toggle::after {
  border: none;
  vertical-align: middle;
  margin-left: 0.8em;
  font-family: FabricMDL2Icons;
  content: "\E70D"; // icon for dropdown
}

// MOSAIC CARD
.dt__group {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  div {
    align-self: center;
  }

  .dt__group--img {
    padding: 0 10px;

    a {
      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  .dt__group--text {
    flex-grow: 4;
    padding-left: 10px;
    text-align: left;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .dt__group--menu {
    height: 100%;
    position: absolute;
    right: 0;

    .dropdown__wrapper {
      height: 100%;

      .ms-Button {
        min-width: 32px;
        height: 100%;
        padding: 4px 10px;
      }

      .dropdown__menu {
        position: fixed !important;
        top: auto !important;
        // left: auto !important;
        transform: translate3d(38px, -46px, 0px) !important;
        position: absolute;
        @include transition(position 0.5s);
        display: none;
        z-index: 9999;

        // &.show {
        //   position: fixed !important;
        // }
      }
    }
  }

  .dt__group--menu-img {
    cursor: pointer;
    width: 96%;
    padding-left: 4%;
  }
}

// ATTACHMENT-SELECTION
app-attachment-selection {
  width: 100%;
}

app-functional-audit {
  width: 100%;
}

// DATA-TABLE COLUMN-ELIPSE
.column__elipse {
  position: relative;

  div {
    position: absolute;
    top: -4px;
    left: -10px;
    background-color: #dddddd;
    opacity: 0;
    display: none;
    padding: 5px 10px;
    z-index: 1050;
    max-width: 800px;
    min-width: 300px;
    font-size: medium;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-height: 1.3;
    @include transition(all .2s);
  }

  &:hover {
    div {
      opacity: 1;
      display: initial;
    }
  }
}

tr {
  td{
  
    &:last-child {
        // background-color: red;
        span.column__elipse {
            div {
                left: auto;
                right: 0;
                // @include transform(translateX(-100%));

            }
        }
    }
  }
}

// .ms-CheckBox-inTable{
//   width: 100%;
//   height: 100%;
//   label{
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     margin: 0;
//   }
//   .ms-CheckBox-field:before{
//     border-radius: 50%;
//   }
// }

.data-table-selection-radio {
  font-size: 50px;
  opacity: 0.2;
}

.data-table-selection-radio:hover {
  opacity: 1;
}

input[type="radio"]:checked {
  opacity: 1 !important;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding-left: 0;
  }
}


.dt__warning {
  background-color: #fff4ce !important;
}

.dt__warning--color {
  color: #fff4ce !important;
}

.dt__severeWarning {
  background-color: #fed9cc !important;
}

.dt__severeWarning--color {
  color: #d83b01 !important;
}

.dt__error {
  background-color: #fde7e9 !important;
}

.dt__error--color {
  color: #a80000 !important;
}

.dt__success {
  background-color: #dff6dd !important;
}

.dt__success--color {
  color: #107c10 !important;
}

.dt__basic {
  background-color: #f4f4f4 !important;
}

.dt__basic--color {
  color: #767676 !important;
}

//* NGX CHART

.chart-legend {
  .legend-labels {
    white-space: normal !important;
  }
}

//* GENERAL MODAL
.modal__notify-text {
  span {
    word-break: break-word;
  }
}