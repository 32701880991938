.btn {
  min-height: var(--input-h);
  min-width: 100px;
  border-radius: 4px !important;
  font-weight: 600;
  margin: 0.5rem;
  cursor: pointer;

  &:hover {
    @include transform(translate(0,-5%));
    box-shadow: var(--shadow);
  }

  &:focus {
    box-shadow: none;
  }
  
  &.btn-principal {
    background-color: var(--primary);
    color: var(--light);
    @include transition(all 0.5s);

    &:hover{
      background-color: var(--primary-20);
    }
  }

  &.btn-seleccionar {
    background-color: var(--dark-3);
    color: var(--light);
    @include transition(all 0.5s);

    &:hover {
      background-color: var(--dark-2);
    }
  }

  &.btn-guardar {
    background-color: var(--green-dark);
    color: var(--light);
    @include transition(all 0.5s);

    &:hover {
      background-color: var(--green);
    }
  }

  &.btn-limpiar {
    background-color: var(--naranja-dark);
    color: var(--light);
    @include transition(all 0.5s);

    &:hover {
      background-color: var(--naranja);
    }
  }

  &.btn-modal-notify {
    color: var(--light) !important;
    border: none;
    @include transition(all 0.5s);

    &:hover {
      background-color: var(--primary-dark);
    }
  }

  &.btn-modal {
    background-color: var(--primary-20);
    color: var(--light);
    border: none;
    @include transition(all 0.5s);

    &:hover {
      background-color: var(--primary);
    }
  }

  &.btn-exportar {
    background-color: var(--primary-70);
    color: var(--dark-2);
  }
}

// BTN ICON

.btn__icon {
  cursor: pointer;
  font-size: 1.5rem;
  // margin: 0.5rem 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;

  i {
    height: 1.5rem;
    width: 1.5rem;
    position: relative;

    &:hover {
      color: var(--primary);
    }

    &:before {
      position: absolute;
      top: 0;
      line-height: 1;
    }
  }

  &.color-red {
    i {
      &:hover {
        color: var(--red);
      }
    }
  }
}

.btn__icon--circle {
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #323130;
  @include transition(all .2s);

  &:hover {
    background-color: var(--primary);
  }

  i {
    @include transition(all .2s);
    height: 32px;
    width: 32px;
    position: relative;
    color: $light;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      line-height: 1;
      @include transform(translate(-50%,-50%));
    }
  }
}
