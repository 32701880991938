.ms-PanelHost,
.ms-Panel {
  z-index: 1070 !important;

  h4 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.ms-Panel-content {
  padding-bottom: 30px !important;
}

.ms-SearchBox {
  height: 32px !important;
  margin-bottom: 0 !important;

  .ms-SearchBox-field {
    height: 32px !important;
    width: 250px !important;
    text-align: left;
    padding: 6px 3px 7px 45px !important;
    &:focus {
      padding: 6px 45px 7px 6px !important;
    }
  }

  .ms-SearchBox-label {
    height: 32px !important;
    line-height: 32px !important;
    margin-bottom: 0 !important;
    display: initial !important;
  }

  // &.is-active {
  //   z-index: auto;

  //   .ms-SearchBox-clear {
  //     display: none;
  //   }
  // }

  &:focus-within {
    .ms-SearchBox-clear {
      display: block;
    }
    .ms-SearchBox-label {
      display: none;
    }
  }
}

.ms-ContextualHost {
  z-index: 1080 !important;
}

.ms-Dialog {
  z-index: 1080 !important;
  min-width: 350px !important;
}

.ms-Overlay {
  z-index: 1060 !important;
}

.ms-Dropdown .ms-Label {
  margin-bottom: 0;
}

.ms-Dropdown {
  .ms-Dropdown-caretDown {
    bottom: 8px;
    color: #999 !important;
    font-size: 0.6rem;
  }

  &.is-open {
    .ms-Dropdown-caretDown {
      @include transform(rotate(180deg));
    }
  }
}

.ms-CommandButton.is-hidden {
  display: inline-block;
}

.ms-CommandButton.ms-CommandBar-overflowButton {
  display: none !important;
}

.ms-CommandButton-dropdownIcon,
.ms-CommandButton-splitIcon {
  display: inline-block !important;
}

.ms-Button {
  margin: 0.5rem;
  border: 1px solid #666;
  background-color: transparent;

  &:hover {
    border: 1px solid #666;
    background-color: #eaeaea;
  }

  &.ms-Button-NoMargin {
    margin: 0;
  }

  &.ms-Button-NoBorder {
    margin: 0;
    border: 1px solid transparent;
  }
}

.ms-TextField-field {
  min-width: 100px;
}

.ms-Pivot {
  .ms-Pivot-links {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    height: auto;

    &:before {
      position: absolute;
      // top: 40px;
      bottom: 0;
      left: 0;
      width: 100%;
      content: "";
      height: 1px;
      background-color: #ccc;
    }
  }

  &.ms-Pivot--tabs {
    .ms-Pivot-links {
      display: initial;

      &:before {
        display: none;
      }

      .ms-Pivot-link:after,
      .ms-Pivot-link:before {
        display: none;
      }
    }
  }
}

// DATA-TABLE

.ms-Table-head,
.ms-Table thead th {
  font-size: 12px;
}

.ms-Table-row,
.ms-Table tr {
  font-size: 13px;
}

//* INPUT FILE

input.ms-TextField-field[type="file"] {
  height: 42px;
}