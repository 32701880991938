@font-face {
  font-family: "BestDocIcons";
  src: url("./assets/styles/fonts/BestDocIcons.eot?dx6cf5");
  src: url("./assets/styles/fonts/BestDocIcons.eot?dx6cf5#iefix") format("embedded-opentype"),
    url("./assets/styles/fonts/BestDocIcons.ttf?dx6cf5") format("truetype"),
    url("./assets/styles/fonts/BestDocIcons.woff?dx6cf5") format("woff"),
    url("./assets/styles/fonts/BestDocIcons.svg?dx6cf5#BestDocIcons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BestDocIcons' !important;
  speak: none;
  position: absolute;
  font-size: 41px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dato-basico:before {
  content: "\f7ff";
}
.icon-tipo-solicitud:before {
  content: "\f2ff";
}
.icon-tema-tipo-solicitud:before {
  content: "\f0ff";
}
.icon-modo-trabajo-solicitud:before {
  content: "\f99f";
}
.icon-estado-solicitud:before {
  content: "\f87a";
}
.icon-Configuracion-solicitud-modificacin-pgina:before {
  content: "\f86a";
}
.icon-documento-estado-solicitud:before {
  content: "\f80a";
}
.icon-ventanilla:before {
  content: "\f376";
}
.icon-swap-2:before {
  content: "\f958";
}
.icon-swap-1:before {
  content: "\f959";
}
.icon-tipo-documental:before {
  content: "\f047";
}
.icon-serie:before {
  content: "\f673";
}
.icon-sub-serie:before {
  content: "\f3f0";
}
.icon-tabla-retencin-documento:before {
  content: "\f488";
}
.icon-temas:before {
  content: "\f77e";
}
.icon-plantilla-sede-empresa:before {
  content: "\f230";
}
.icon-consecutivo:before {
  content: "\f333";
}
.icon-solicitud-estado:before {
  content: "\f765";
}
.icon-microphone:before {
  content: "\f130";
}
.icon-plus:before {
  content: "\f067";
}
.icon-question:before {
  content: "\f128";
}
.icon-hammer:before {
  content: "\f6e3";
}
.icon-search:before {
  content: "\f002";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-user-tie:before {
  content: "\f508";
}
.icon-film:before {
  content: "\f008";
}
.icon-th:before {
  content: "\f00a";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-power-off:before {
  content: "\f011";
}
.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-trash-alt:before {
  content: "\f2ed";
}
.icon-home:before {
  content: "\f015";
}
.icon-file:before {
  content: "\f15b";
}
.icon-arrow-alt-circle-down:before {
  content: "\f358";
}
.icon-arrow-alt-circle-up:before {
  content: "\f35b";
}
.icon-sync:before {
  content: "\f021";
}
.icon-list-alt:before {
  content: "\f022";
}
.icon-flag:before {
  content: "\f024";
}
.icon-headphones:before {
  content: "\f025";
}
.icon-folder-plus:before {
  content: "\f65e";
}
.icon-list:before {
  content: "\f03a";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-pencil-alt:before {
  content: "\f303";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-edit:before {
  content: "\f044";
}
.icon-pencil-square-o:before {
  content: "\f044";
}
.icon-check-square:before {
  content: "\f14a";
}
.icon-step-backward:before {
  content: "\f048";
}
.icon-fast-backward:before {
  content: "\f049";
}
.icon-backward:before {
  content: "\f04a";
}
.icon-play:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-stop:before {
  content: "\f04d";
}
.icon-forward:before {
  content: "\f04e";
}
.icon-fast-forward:before {
  content: "\f050";
}
.icon-step-forward:before {
  content: "\f051";
}
.icon-eject:before {
  content: "\f052";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-times-circle:before {
  content: "\f057";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-check-double:before {
  content: "\f560";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-exclamation-triangle:before {
  content: "\f071";
}
.icon-warning:before {
  content: "\f071";
}
.icon-border-all:before {
  content: "\f84c";
}
.icon-door-open:before {
  content: "\f52b";
}
.icon-key:before {
  content: "\f084";
}
.icon-cogs:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-sign-in-alt:before {
  content: "\f2f6";
}
.icon-phone:before {
  content: "\f095";
}
.icon-phone-square:before {
  content: "\f098";
}
.icon-ruler:before {
  content: "\f545";
}
.icon-bell:before {
  content: "\f0f3";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-tasks:before {
  content: "\f0ae";
}
.icon-filter:before {
  content: "\f0b0";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-clipboard:before {
  content: "\f328";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-list-ul:before {
  content: "\f0ca";
}
.icon-table:before {
  content: "\f0ce";
}
.icon-money-bill:before {
  content: "\f0d6";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-columns:before {
  content: "\f0db";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-unsorted:before {
  content: "\f0dc";
}
.icon-envelope-square:before {
  content: "\f199";
}
.icon-comment:before {
  content: "\f075";
}
.icon-comments:before {
  content: "\f086";
}
.icon-sitemap:before {
  content: "\f0e8";
}
.icon-exchange-alt:before {
  content: "\f362";
}
.icon-file-alt:before {
  content: "\f15c";
}
.icon-industry:before {
  content: "\f275";
}
.icon-city:before {
  content: "\f64f";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-mobile-alt:before {
  content: "\f3cd";
}
.icon-spinner:before {
  content: "\f110";
}
.icon-folder:before {
  content: "\f07b";
}
.icon-folder-open:before {
  content: "\f07c";
}
.icon-code:before {
  content: "\f121";
}
.icon-info:before {
  content: "\f129";
}
.icon-exclamation:before {
  content: "\f12a";
}
.icon-calendar:before {
  content: "\f133";
}
.icon-unlock-alt:before {
  content: "\f13e";
}
.icon-file-invoice-dollar:before {
  content: "\f571";
}
.icon-user-tag:before {
  content: "\f507";
}
.icon-database:before {
  content: "\f1c0";
}
.icon-plus-square:before {
  content: "\f0fe";
}
.icon-delicious:before {
  content: "\f1a5";
}
.icon-building:before {
  content: "\f1ad";
}
.icon-broom:before {
  content: "\f1b2";
}
.icon-code-branch:before {
  content: "\f126";
}
.icon-file-pdf:before {
  content: "\f1c1";
}
.icon-file-word:before {
  content: "\f1c2";
}
.icon-file-excel:before {
  content: "\f1c3";
}
.icon-file-powerpoint:before {
  content: "\f1c4";
}
.icon-file-image:before {
  content: "\f1c5";
}
.icon-file-photo-o:before {
  content: "\f1c5";
}
.icon-file-picture-o:before {
  content: "\f1c5";
}
.icon-file-archive-o:before {
  content: "\f1c6";
}
.icon-file-zip-o:before {
  content: "\f1c6";
}
.icon-file-csv:before {
  content: "\f6dd";
}
.icon-file-code:before {
  content: "\f1c9";
}
.icon-file-signature:before {
  content: "\f573";
}
.icon-project-diagram:before {
  content: "\f542";
}
.icon-sliders:before {
  content: "\f1de";
}
.icon-newspaper:before {
  content: "\f1ea";
}
.icon-paint-brush:before {
  content: "\f1fc";
}
.icon-chart-area:before {
  content: "\f1fe";
}
.icon-tools:before {
  content: "\f7d9";
}
.icon-toolbox:before {
  content: "\f552";
}
.icon-server:before {
  content: "\f233";
}
.icon-user-friends:before {
  content: "\f500";
}
.icon-user-cog:before {
  content: "\f4fe";
}
.icon-battery-three-quarters:before {
  content: "\f241";
}
.icon-battery-half:before {
  content: "\f242";
}
.icon-layer-group:before {
  content: "\f5fd";
}
.icon-file-import:before {
  content: "\f56f";
}
.icon-file-export:before {
  content: "\f56e";
}
.icon-user-shield:before {
  content: "\f505";
}
.icon-calendar-check:before {
  content: "\f274";
}
.icon-map-pin:before {
  content: "\f276";
}
.icon-map-marked-alt:before {
  content: "\f5a0";
}
.icon-suitcase-rolling:before {
  content: "\f5c1";
}
.icon-phone-square-alt:before {
  content: "\f87b";
}
.icon-envelope-open-text:before {
  content: "\f658";
}
.icon-address-book:before {
  content: "\f2b9";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-user:before {
  content: "\f007";
}
.icon-bookmark:before {
  content: "\f02e";
}
.icon-print:before {
  content: "\f02f";
}
.icon-certificate:before {
  content: "\f0a3";
}
.icon-box:before {
  content: "\f466";
}
.icon-tag:before {
  content: "\f02b";
}
.icon-award:before {
  content: "\f559";
}
.icon-map-marker-alt:before {
  content: "\f3c5";
}
.icon-clock:before {
  content: "\f017";
}
.icon-share-alt:before {
  content: "\f1e0";
}